
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import {
  markdownMetadataDescription,
  metadata,
} from '~/assets/ts/utils/metadata'
import { Broadcaster } from '~/models/broadcaster'
import { Webcast } from '~/models/webcast'
import { WebcastApi } from '~/apiclient/apiwebcasts'
import KeydownEvent from '~/models/generic/KeydownEvent'
import {
  BroadcasterDisplay,
  PlayerMediaType,
  SpeakerDisplay,
} from '~/assets/ts/enums'
import { Speaker } from '~/models/speaker'
import { siteSpeakerUrl, siteWebcastUrl } from '~/assets/ts/utils/urls'
import SiteMediaTypeSwitcher from '~/components/site/MediaTypeSwitcher.vue'
import PlayerElement from '~/components/player/Element.vue'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'
import SiteLayoutWithPromo from '~/components/site/layout/WithPromo.vue'
import EmbedButton from '~/components/_general/EmbedButton.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import ShareButton from '~/components/_general/ShareButton.vue'
import SpeakerElement from '~/components/_general/SpeakerElement.vue'
import BroadcasterElement from '~/components/_general/BroadcasterElement.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import LivePoller from '~/components/_general/LivePoller.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'

export default Vue.extend({
  components: {
    HorizontalRule,
    LivePoller,
    MarkdownElement,
    BroadcasterElement,
    SpeakerElement,
    ShareButton,
    InlineIcon,
    EmbedButton,
    SiteLayoutWithPromo,
    KeydownObserver,
    PlayerElement,
    SiteMediaTypeSwitcher,
  },
  layout: 'site',
  middleware: ['fetchBroadcaster', 'fetchWebcast'],
  data() {
    return {
      polledOnline: undefined as boolean | undefined,
      offlineWebcastApi: undefined as WebcastApi | undefined,
      localMediaType: undefined as PlayerMediaType | undefined,
    }
  },
  head(this: any) {
    return metadata({
      title: `${this.broadcastTitle} | ${this.broadcaster.displayName}`,
      description: markdownMetadataDescription(this.broadcastDescription),
    })
  },
  computed: {
    audioOnly(): boolean {
      return !!this.webcast?.AudioOnly
    },
    mediaType(): PlayerMediaType {
      if (this.audioOnly) return PlayerMediaType.Audio
      if (this.localMediaType !== undefined) return this.localMediaType
      return PlayerMediaType.Video
    },
    SpeakerDisplay() {
      return SpeakerDisplay
    },
    BroadcasterDisplay() {
      return BroadcasterDisplay
    },
    PlayerMediaType() {
      return PlayerMediaType
    },
    video(): boolean {
      return this.mediaType === PlayerMediaType.Video
    },
    speaker(): Speaker | undefined {
      return this.webcast?.speaker
    },
    speakerUrl(): string | undefined {
      if (!this.speaker) return undefined
      return siteSpeakerUrl(this.speaker)
    },
    speakerName(): string {
      return this.webcast?.speakerName ?? ''
    },
    broadcastTitle(): TranslateResult {
      return this.webcast?.Title(this) ?? this.$t('Webcast Offline')
    },
    broadcastDescription(): TranslateResult | undefined {
      if (this.webcast) {
        return this.webcast.description ?? undefined
      }
      return this.$t('Return when this broadcaster is online')
    },
    broadcasterID(): string {
      return this.$route.params.broadcasterID
    },
    broadcaster(): Broadcaster {
      return new Broadcaster(
        this.$store.getters['broadcasters/broadcaster'](this.broadcasterID)
      )
    },
    live(): boolean {
      return this.polledOnline ?? !!this.webcastApi
    },
    webcastApi(): WebcastApi | undefined {
      return this.$store.getters['webcasts/broadcasterWebcast'](
        this.broadcasterID
      )
    },
    webcast(): Webcast | undefined {
      const webcast = this.webcastApi ? this.webcastApi : this.offlineWebcastApi
      return webcast ? new Webcast(webcast) : undefined
    },
    playCount(): number {
      return this.webcast?.totalTuneInCount ?? 0
    },
    shareUrl(): string {
      return siteWebcastUrl(this.broadcaster)
    },
  },
  methods: {
    isOnline(live: boolean) {
      this.polledOnline = live
      if (this.webcastApi) {
        this.offlineWebcastApi = this.webcastApi
      }
    },
    keydown(key: KeydownEvent) {
      if (key.A && this.video) {
        this.setMediaType(PlayerMediaType.Audio)
      } else if (key.V && !this.video) {
        this.setMediaType(PlayerMediaType.Video)
      }
    },
    setMediaType(mediaType: PlayerMediaType) {
      this.localMediaType = mediaType
    },
  },
})
