
import Vue, { PropType } from 'vue'
import { PlayerMediaType } from '~/assets/ts/enums'
import NavigationTab from '~/components/_general/NavigationTab.vue'

export default Vue.extend({
  name: 'SiteMediaTypeSwitcher',
  components: { NavigationTab },
  props: {
    hasVideo: {
      type: Boolean,
    },
    hasAudio: {
      type: Boolean,
      default: true,
    },
    mediaType: {
      type: Number as PropType<PlayerMediaType>,
      default: PlayerMediaType.Audio,
    },
  },
  computed: {
    PlayerMediaType() {
      return PlayerMediaType
    },
    videoActive(): boolean {
      return this.mediaType === PlayerMediaType.Video
    },
  },
})
